// Home.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faChessKing, faLeaf, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import SplineViewer from './components/SplineViewer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Dynamically load the Spline Viewer script
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@1.7.2/build/spline-viewer.js';
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const types = [
    {
      key: 'minoxidil5',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil5.png`,
      link: 'order',
    },
    {
      key: 'minoxidilLowAlcohol',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilLowAlcohol.png`,
      link: 'order',
    },
    {
      key: 'minoxidil2Women',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil2Women.png`,
      link: 'order',
    },
    {
      key: 'dermaroller',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Dermaroller.png`,
      link: 'order',
    },
    {
      key: 'foam',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilFoam.png`,
      link: 'order',
    },
    {
      key: 'foamWomen',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilWomenFoam.png`,
      link: 'order',
    },
    {
      key: 'shampooMenSmall',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenSmall.png`,
      link: 'order',
    },
    {
      key: 'shampooMenBig',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenBig.png`,
      link: 'order',
    },
    {
      key: 'shampooWomenSmall',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenSmall.png`,
      link: 'order',
    },
    {
      key: 'shampooWomenBig',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenBig.png`,
      link: 'order',
    },
  ];

  const reviewData = [
    {
      key: 'minoxidil5',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil5.png`,
      link: 'order',
      reviews: {
        fiveStars: 23,
        fourStars: 17,
        threeStars: 8,
        twoStars: 3,
        oneStar: 1,
      },
    },
    {
      key: 'minoxidilLowAlcohol',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilLowAlcohol.png`,
      link: 'order',
      reviews: {
        fiveStars: 15,
        fourStars: 10,
        threeStars: 5,
        twoStars: 2,
        oneStar: 1,
      },
    },
    {
      key: 'minoxidil2Women',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil2Women.png`,
      link: 'order',
      reviews: {
        fiveStars: 16,
        fourStars: 9,
        threeStars: 4,
        twoStars: 1,
        oneStar: 0,
      },
    },
    {
      key: 'dermaroller',
      imgSrc: `${process.env.PUBLIC_URL}/assets/Dermaroller.png`,
      link: 'order',
      reviews: {
        fiveStars: 30,
        fourStars: 20,
        threeStars: 10,
        twoStars: 5,
        oneStar: 2,
      },
    },
    {
      key: 'foam',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilFoam.png`,
      link: 'order',
      reviews: {
        fiveStars: 12,
        fourStars: 6,
        threeStars: 2,
        twoStars: 0,
        oneStar: 0,
      },
    },
    {
      key: 'foamWomen',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilWomenFoam.png`,
      link: 'order',
      reviews: {
        fiveStars: 18,
        fourStars: 12,
        threeStars: 4,
        twoStars: 1,
        oneStar: 0,
      },
    },
    {
      key: 'shampooMenSmall',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenSmall.png`,
      link: 'order',
      reviews: {
        fiveStars: 10,
        fourStars: 8,
        threeStars: 3,
        twoStars: 1,
        oneStar: 0,
      },
    },
    {
      key: 'shampooMenBig',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenBig.png`,
      link: 'order',
      reviews: {
        fiveStars: 25,
        fourStars: 15,
        threeStars: 5,
        twoStars: 2,
        oneStar: 1,
      },
    },
    {
      key: 'shampooWomenSmall',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenSmall.png`,
      link: 'order',
      reviews: {
        fiveStars: 14,
        fourStars: 9,
        threeStars: 3,
        twoStars: 1,
        oneStar: 0,
      },
    },
    {
      key: 'shampooWomenBig',
      imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenBig.png`,
      link: 'order',
      reviews: {
        fiveStars: 22,
        fourStars: 13,
        threeStars: 4,
        twoStars: 1,
        oneStar: 0,
      },
    },
  ];

  return (
    <div>
      <Navbar />
      <header className="header-home" id="intro">
        <div className="container header__container">
          <div className="header__left">
            <h1>{t('header.title')}</h1>
            <p>{t('header.description')}</p>
            <a href="/order" className="btn btn-secondary">{t('header.orderButton')}</a>
          </div>
          <div className="header__right" id="spline-container">
            <SplineViewer url="https://prod.spline.design/TRQZ0-9RnYAfXJnm/scene.splinecode" />
          </div>
        </div>
        <img
          srcSet={`${process.env.PUBLIC_URL}/assets/Hero Image-2000w.jpg 2000w,
                   ${process.env.PUBLIC_URL}/assets/Hero Image-1000w.jpg 1000w,
                   ${process.env.PUBLIC_URL}/assets/Hero Image-500w.jpg 500w`}
          sizes="(min-width: 1000px) 2000px, (min-width: 600px) 1000px, 500px"
          src={`${process.env.PUBLIC_URL}/assets/Hero Image-500w.jpg`}
          alt="Hero Image"
          style={{ display: 'none' }}
        />
      </header>

      <section className="about-section">
        <div className="about-content">
          <div className="about-left">
            <img src={`${process.env.PUBLIC_URL}/assets/MinoCare Logo.png`} alt="Logo" className="about-image" />
            <h1>{t('logoShowcase.title')}</h1>
          </div>
          <div className="about-right">
            <h2>{t('about.title')}</h2>
            <p>{t('about.description')}</p>
            <a href="https://www.instagram.com/minocare.al/" className="btn btn-secondary">{t('about.getToKnowButton')}</a>
          </div>
        </div>
      </section>

      <h2 className='title-mino'>{t('whyUs.title')}</h2>
      <section className="card-section container">
        <div className="card">
          <h1>{t('whyUs.effective.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faCog} className="feature-icon" />
            <p>{t('whyUs.effective.description')}</p>
          </div>
        </div>
        <div className="card">
          <h1>{t('whyUs.selfEsteem.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faChessKing} className="feature-icon" />
            <p>{t('whyUs.selfEsteem.description')}</p>
          </div>
        </div>
        <div className="card">
          <h1>{t('whyUs.natural.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faLeaf} className="feature-icon" />
            <p>{t('whyUs.natural.description')}</p>
          </div>
        </div>
      </section>

      {types.length > 0 && (
        <section className="container types-section">
          <h2 className="types-title">{t('products.title')}</h2>
          <Swiper
            spaceBetween={100}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              bulletClass: 'product-swiper-pagination-bullet',
              bulletActiveClass: 'product-swiper-pagination-bullet-active',
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 100,
              },
            }}
          >
            {types.map((type, index) => (
              <SwiperSlide key={index}>
                <div className="types-slide">
                  <img src={type.imgSrc} alt={t(`products.${type.key}.title`)} />
                  <div>
                    <h4>{t(`products.${type.key}.title`)}</h4>
                    <p>{t(`products.${type.key}.description`)}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
          </Swiper>
          <a href={`order`} className="btn btn-secondary order-btn">{t('products.orderButton')}</a>
        </section>
      )}

      {reviewData.length > 0 && (
        <section className="container reviews-section">
          <h2 className="reviews-title">{t('reviews.title')}</h2>
          <Swiper
            spaceBetween={100}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              bulletClass: 'review-swiper-pagination-bullet',
              bulletActiveClass: 'review-swiper-pagination-bullet-active',
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 100,
              },
            }}
          >
            {reviewData.map((product, index) => (
              <SwiperSlide key={index}>
                <div className="review-slide">
                  <img src={product.imgSrc} alt={t(`reviews.${product.key}.title`)} />
                  <div className="review-content">
                    <h4>{t(`reviews.${product.key}.title`)}</h4>
                    <div className="stars">
                      <div className="star">
                        ★ ★ ★ ★ ★ <div className="slider" style={{ width: `${(product.reviews.fiveStars / 30) * 100}%` }}></div> {product.reviews.fiveStars}
                      </div>
                      <div className="star">
                        ★ ★ ★ ★ ☆ <div className="slider" style={{ width: `${(product.reviews.fourStars / 30) * 100}%` }}></div> {product.reviews.fourStars}
                      </div>
                      <div className="star">
                        ★ ★ ★ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.threeStars / 30) * 100}%` }}></div> {product.reviews.threeStars}
                      </div>
                      <div className="star">
                        ★ ★ ☆ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.twoStars / 30) * 100}%` }}></div> {product.reviews.twoStars}
                      </div>
                      <div className="star">
                        ★ ☆ ☆ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.oneStar / 30) * 100}%` }}></div> {product.reviews.oneStar}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
          </Swiper>
          <a href={`order`} className="btn btn-secondary order-btn">{t('products.orderButton')}</a>
        </section>
      )}

      <section id="logo-showcase" className="container logo-showcase">
        <img src={`${process.env.PUBLIC_URL}/assets/MinoCare Logo.png`} alt="Synaps Logo" className="logo-image" />
      </section>
      <h1 className='logo-showcase-brand'>{t('header.title')}</h1>
      <h1 className='logo-showcase-slogan'>{t('logoShowcase.title')}</h1>

      <div className="container footer-img">
        <div className="footer-bottom">
          <div className="footer-column footer-social">
            <h4>{t('footer.location')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <FontAwesomeIcon icon={faLocationDot} className="footer-img location-icon" />
                <a href="https://maps.app.goo.gl/Tpt5iiLCwdZZ1Dak9" className="footer-link">{t('footer.locationLink')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.contact')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/email.png`} alt="Email" />
                <a href="mailto:contact@minocare.al" className="footer-link">{t('footer.email')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/phone-call.png`} alt="Phone Number" />
                <a href="tel:+355696664258" className="footer-link">{t('footer.phone')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.socials')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/facebook.png`} alt="Facebook" />
                <a href="https://www.facebook.com" className="footer-link">{t('footer.facebook')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/instagram.png`} alt="Instagram" />
                <a href="https://www.instagram.com/minocare.al/" className="footer-link">{t('footer.instagram')}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          <p className="footer-copy">{t('footer.copyright')}</p>
          <p className="footer-copy-synaps">{t('footer.designedBy')} <a className="blog-log-in-btn footer-copy-designed-by-synaps" href="https://www.synapslimited.eu">{t('footer.designedByLink')}</a></p>
        </div>
      </div>
    </div>
  );
}

export default Home;
